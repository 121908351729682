import React from 'react';

import { SkeletonLoader } from './SkeletonLoader';

export const SkeletonLoaderMyBooking = () => {
    const theme = {
        items: [
            {
                height: 20,
            },
        ],
    };

    return (
        <>
            <SkeletonLoader desktop={theme} mobile={theme} theme="once" />
            <SkeletonLoader desktop={theme} mobile={theme} theme="once" />
        </>
    );
};
