import React from 'react';
import { bool, number, object, string } from 'prop-types';
import { Wrapper } from 'shared';

import { Loader } from './Loader';
import cx from './SkeletonLoader.sss';

export const SkeletonLoader = ({
    desktop,
    maxWidth,
    mobile,
    theme,
    title,
    withoutWrapper,
    backgroundColor,
    backgroundOpacity,
    foregroundColor,
    foregroundOpacity,
}) => {
    const renderItem = (item, level, index, deviceType) => (
        <div
            key={`${level}_${index + 1}`}
            style={{
                height: item.height,
                marginTop: item.marginTop,
            }}
            className={cx('item')}
        >
            <Loader
                uniqueKey={`SkeletonLoaderItem_${level}_${
                    index + 1
                }_${deviceType}`}
                backgroundColor={backgroundColor}
                backgroundOpacity={backgroundOpacity}
                foregroundColor={foregroundColor}
                foregroundOpacity={foregroundOpacity}
                {...item}
            />
        </div>
    );

    const renderItems = (level, deviceType) => (data, index) => {
        const {
            bottom,
            height,
            items,
            left,
            marginTop,
            right,
            top,
            type,
            width,
            marginBottom,
        } = data;

        switch (type) {
            case 'flex':
                return (
                    <div
                        key={`${level}_${type}_${index + 1}`}
                        style={{
                            bottom,
                            height,
                            left,
                            marginBottom,
                            marginTop,
                            position:
                                top || left || right || bottom
                                    ? 'absolute'
                                    : 'relative',
                            right,
                            top,
                            width,
                        }}
                        className={cx('flex')}
                    >
                        {items && items.map(renderItems(level + 1, deviceType))}
                    </div>
                );

            case 'circle':
                return (
                    <div
                        key={`${level}_${type}_${index + 1}`}
                        style={{
                            bottom,
                            height,
                            left,
                            marginBottom,
                            marginTop,
                            position:
                                top || left || right || bottom
                                    ? 'absolute'
                                    : 'relative',
                            right,
                            top,
                            width,
                        }}
                        className={cx('circle')}
                    >
                        <Loader
                            {...data}
                            uniqueKey={`SkeletonLoaderCircle_${level}_${type}_${
                                index + 1
                            }_${deviceType}`}
                            bottom={null}
                            left={null}
                            right={null}
                            top={null}
                        />
                    </div>
                );

            case 'items':
                return (
                    <div
                        key={`${level}_${type}_${index + 1}`}
                        style={{
                            bottom,
                            height,
                            left,
                            marginBottom,
                            marginTop,
                            position:
                                top || left || right || bottom
                                    ? 'absolute'
                                    : 'relative',
                            right,
                            top,
                            width,
                        }}
                        className={cx('items')}
                    >
                        {items && items.map(renderItems(level + 1, deviceType))}
                    </div>
                );

            default:
                return renderItem(data, level + 1, index, deviceType);
        }
    };

    // так как для desktop и mobile используются одни и те же идентификаторы
    // а в разметке ондовремено рисутствуют и desktop и mobile лоадеры
    // идентификаторы будут конфликтовать, и на mobile лоадер не будет отображться
    // так как лоадер с таким же id находится в блоке desktop, который скрыт через
    // стили display: none, поэтому делаем уникальные id для desktop и mobile
    const renderType = (type, deviceType) => {
        const { height, width, items } = type;

        return (
            <div className={cx('content')} style={{ height, width }}>
                {items && items.map(renderItems(0, deviceType))}
            </div>
        );
    };

    const renderBox = () => {
        return (
            <div className={cx('SkeletonLoader')} style={{ maxWidth }}>
                <div className={cx('desktop')}>
                    {title && (
                        <div className={cx('title')}>
                            <Loader
                                height={20}
                                uniqueKey="SkeletonLoaderTitle"
                                width={title}
                            />
                        </div>
                    )}
                    {renderType(desktop, 'Desktop')}
                </div>
                <div className={cx('mobile')}>
                    {title && (
                        <div className={cx('title')}>
                            <Loader
                                height={20}
                                uniqueKey="SkeletonLoaderTitleMobile"
                                width={title}
                            />
                        </div>
                    )}
                    {renderType(mobile, 'Mobile')}
                </div>
            </div>
        );
    };

    const WrapperCmp = withoutWrapper ? 'div' : Wrapper;

    return (
        <div
            className={cx('root', {
                [`theme_${theme}`]: theme,
            })}
        >
            <WrapperCmp>{renderBox()}</WrapperCmp>
        </div>
    );
};

SkeletonLoader.propTypes = {
    backgroundColor: string,
    backgroundOpacity: number,
    desktop: object,
    foregroundColor: string,
    foregroundOpacity: number,
    maxWidth: number,
    mobile: object,
    theme: string,
    title: number,
    withoutWrapper: bool,
};
