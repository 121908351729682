import React from 'react';
import { bool } from 'prop-types';
import { ContentLoaderBase } from 'shared';

import cx from './SkeletonLoaderAuthorization.sss';

const MobileLoader = () => (
    <ContentLoaderBase
        backgroundColor="#ECEFF5"
        foregroundColor="#ECEFF5"
        height={489}
        speed={1.5}
        title={null}
        uniqueKey="SkeletonLoaderAuthorizationMobile"
        width={360}
    >
        <rect height="28" rx="0" ry="0" width="320" x="16" y="30" />

        <rect height="54" rx="0" ry="0" width="326" x="16" y="75" />
        <rect height="54" rx="0" ry="0" width="326" x="16" y="155" />

        <rect height="16" rx="0" ry="0" width="108" x="236" y="233" />

        <rect height="56" rx="0" ry="0" width="326" x="16" y="300" />

        <rect height="18" rx="0" ry="0" width="210" x="16" y="380" />
        <rect height="38" rx="0" ry="0" width="328" x="16" y="415" />
    </ContentLoaderBase>
);

const DesktopLoader = () => (
    <ContentLoaderBase
        backgroundColor="#ECEFF5"
        foregroundColor="#ECEFF5"
        height={552}
        speed={1.5}
        uniqueKey="SkeletonLoaderAuthorization"
        width={360}
    >
        <rect height="32" rx="0" ry="0" width="296" x="32" y="40" />

        <rect height="56" rx="0" ry="0" width="296" x="32" y="92" />
        <rect height="56" rx="0" ry="0" width="296" x="32" y="172" />

        <rect height="24" rx="0" ry="0" width="148" x="180" y="252" />

        <rect height="48" rx="8" ry="8" width="296" x="32" y="300" />
        <rect height="48" rx="8" ry="8" width="296" x="32" y="360" />

        <rect height="16" rx="0" ry="0" width="148" x="32" y="432" />
        <rect height="48" rx="0" ry="0" width="296" x="32" y="460" />
    </ContentLoaderBase>
);

const SkeletonLoaderAuthorization = ({ isModal }) => (
    <div className={cx('root', { isModal })}>
        <div className={cx('desktop')}>
            <DesktopLoader />
        </div>
        <div className={cx('mobile')}>
            <MobileLoader />
        </div>
    </div>
);

SkeletonLoaderAuthorization.propTypes = {
    isModal: bool,
};

export default SkeletonLoaderAuthorization;
