import React from 'react';
import { bool } from 'prop-types';

import { SkeletonLoader } from './SkeletonLoader';

export const SkeletonLoaderProfileHeader = ({ isPublic }) => {
    const items = isPublic
        ? [
              {
                  height: 120,
                  left: 90,
                  top: 26,
                  type: 'circle',
                  width: 120,
              },
              {
                  height: 24,
                  marginTop: 170,
              },
          ]
        : [
              {
                  height: 120,
                  left: 0,
                  top: 48,
                  type: 'circle',
                  width: 120,
              },
              {
                  bottom: 16,
                  items: [
                      {
                          height: 20,
                          width: 120,
                      },
                      {
                          height: 20,
                          width: 120,
                      },
                      {
                          height: 20,
                          width: 120,
                      },
                      {
                          height: 20,
                          width: 120,
                      },
                      {
                          height: 20,
                          width: 120,
                      },
                  ],
                  left: 8,
                  type: 'flex',
              },
              {
                  bottom: 16,
                  items: [
                      {
                          height: 20,
                          width: 120,
                      },
                  ],
                  right: 0,
                  type: 'flex',
                  width: 120,
              },
              {
                  height: 32,
                  items: [
                      {
                          height: 32,
                          width: 264,
                      },
                  ],
                  left: 152,
                  top: 48,
                  type: 'flex',
                  width: 264,
              },
              {
                  height: 20,
                  items: [
                      {
                          height: 20,
                          width: 264,
                      },
                  ],
                  left: 152,
                  top: 88,
                  type: 'flex',
                  width: 264,
              },
              {
                  height: 44,
                  items: [
                      {
                          height: 44,
                          width: 120,
                      },
                      {
                          height: 44,
                          width: 120,
                      },
                      {
                          height: 44,
                          width: 120,
                      },
                  ],
                  left: 152,
                  top: 128,
                  type: 'flex',
              },
          ];

    const itemsMobile = isPublic
        ? [
              {
                  height: 64,
                  left: 118,
                  top: 26,
                  type: 'circle',
                  width: 64,
              },
              {
                  height: 18,
                  marginTop: 110,
              },
          ]
        : [
              {
                  height: 80,
                  left: 16,
                  top: 22,
                  type: 'circle',
                  width: 80,
              },
              {
                  bottom: 16,
                  items: [
                      {
                          height: 20,
                          width: 64,
                      },
                      {
                          height: 20,
                          width: 64,
                      },
                      {
                          height: 20,
                          width: 64,
                      },
                      {
                          height: 20,
                          width: 64,
                      },
                  ],
                  left: 24,
                  type: 'flex',
              },
              {
                  height: 24,
                  items: [
                      {
                          height: 24,
                          width: 232,
                      },
                  ],
                  left: 112,
                  top: 20,
                  type: 'flex',
                  width: 232,
              },
              {
                  height: 20,
                  items: [
                      {
                          height: 20,
                          width: 232,
                      },
                  ],
                  left: 112,
                  top: 53,
                  type: 'flex',
                  width: 232,
              },
              {
                  height: 20,
                  items: [
                      {
                          height: 20,
                          width: 140,
                      },
                  ],
                  left: 112,
                  top: 82,
                  type: 'flex',
                  width: 140,
              },
          ];

    return (
        <SkeletonLoader
            desktop={{
                height: 248,
                items,
                ...(isPublic ? { width: 300 } : {}),
            }}
            mobile={{
                height: 168,
                items: itemsMobile,
                ...(isPublic ? { width: 300 } : {}),
            }}
            theme={isPublic ? 'center' : 'simple'}
        />
    );
};

SkeletonLoaderProfileHeader.propTypes = {
    isPublic: bool,
};
