import React from 'react';

import { SkeletonLoader } from './SkeletonLoader';

export const SkeletonLoaderChangePassword = () => (
    <SkeletonLoader
        desktop={{
            items: [
                { height: 24 },
                { height: 56 },
                { height: 24 },
                {
                    items: [{ height: 56 }, { height: 56 }],
                    type: 'flex',
                },
            ],
        }}
        mobile={{
            items: [
                { height: 20 },
                { height: 54 },
                { height: 20 },
                { height: 54 },
                { height: 54 },
            ],
        }}
        title={200}
    />
);
