import React from 'react';

import { SkeletonLoader } from './SkeletonLoader';

export const SkeletonLoaderUserInfo = () => (
    <SkeletonLoader
        desktop={{
            height: 84,
            items: [
                {
                    height: 14,
                    top: 3,
                    width: 180,
                },
                {
                    height: 44,
                    items: [
                        {
                            height: 44,
                            width: 120,
                        },
                        {
                            height: 44,
                            width: 120,
                        },
                        {
                            height: 44,
                            width: 120,
                        },
                    ],
                    left: 0,
                    top: 40,
                    type: 'flex',
                    width: 408,
                },
            ],
        }}
        mobile={{
            height: 40,
            items: [
                {
                    height: 18,
                    width: 130,
                },
                {
                    height: 18,
                    left: 0,
                    top: 20,
                    width: 200,
                },
            ],
        }}
        theme="simple"
    />
);
