import React from 'react';
import { number } from 'prop-types';

import { SkeletonLoader } from './SkeletonLoader';

const SkeletonLoaderTransactionsDiagram = ({ size = 120 }) => (
    <SkeletonLoader
        desktop={{
            height: size,
            items: [
                {
                    height: size,
                    width: 20,
                },
            ],
        }}
        mobile={{
            height: size,
            items: [
                {
                    height: size,
                    width: 20,
                },
            ],
        }}
        theme="simple"
        withoutWrapper
    />
);

SkeletonLoaderTransactionsDiagram.propTypes = {
    size: number,
};

export { SkeletonLoaderTransactionsDiagram };
