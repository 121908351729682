import React from 'react';

import { SkeletonLoader } from './SkeletonLoader';

export const SkeletonLoaderProfileMiles = () => {
    const theme = {
        items: [
            {
                items: [
                    {
                        items: [
                            {
                                height: 20,
                                width: 100,
                            },
                            {
                                height: 26,
                                marginTop: -10,
                                width: 120,
                            },
                        ],
                        type: 'items',
                        width: '50%',
                    },
                    {
                        items: [
                            {
                                height: 20,
                                width: 100,
                            },
                            {
                                height: 24,
                                type: 'circle',
                                width: 24,
                            },
                        ],
                        type: 'flex',
                    },
                ],
                type: 'flex',
            },
            {
                height: 22,
                marginTop: 40,
            },
            {
                height: 22,
                marginTop: -10,
            },
            {
                items: [
                    {
                        items: [
                            {
                                height: 16,
                                width: 120,
                            },
                            {
                                height: 16,
                                marginTop: -10,
                                width: 100,
                            },
                        ],
                        type: 'items',
                        width: '50%',
                    },
                    {
                        items: [
                            {
                                height: 40,
                                width: 160,
                            },
                        ],
                        type: 'flex',
                    },
                ],
                marginTop: 20,
                type: 'flex',
            },
            {
                height: 16,
                marginTop: 40,
                width: 150,
            },
            {
                height: 16,
                marginTop: -10,
            },
            {
                height: 16,
                marginTop: -10,
            },
            {
                height: 16,
                marginTop: -10,
            },
            {
                height: 16,
                marginTop: -10,
            },
        ],
    };

    return <SkeletonLoader desktop={theme} mobile={theme} title={250} />;
};
