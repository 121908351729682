import React from 'react';
import { number, oneOfType, string } from 'prop-types';
import { ContentLoaderBase } from 'shared';

import cx from './Loader.sss';

export const Loader = ({
    width,
    height,
    top,
    left,
    right,
    bottom,
    uniqueKey,
    backgroundColor,
    backgroundOpacity,
    foregroundColor,
    foregroundOpacity,
}) => (
    <div
        style={{
            bottom,
            height,
            left,
            position: top || left || right || bottom ? 'absolute' : 'relative',
            right,
            top,
            width,
        }}
        className={cx('Loader')}
    >
        <div className={cx('box')}>
            <ContentLoaderBase
                backgroundColor={backgroundColor}
                backgroundOpacity={backgroundOpacity}
                foregroundColor={foregroundColor}
                foregroundOpacity={foregroundOpacity}
                height={1000}
                speed={1.5}
                title={null}
                uniqueKey={uniqueKey}
                width={1000}
            >
                <rect height="1000" rx="0" ry="0" width="1000" x="0" y="0" />
            </ContentLoaderBase>
        </div>
    </div>
);

Loader.propTypes = {
    backgroundColor: string,
    backgroundOpacity: number,
    bottom: oneOfType([number, string]),
    foregroundColor: string,
    foregroundOpacity: number,
    height: oneOfType([number, string]),
    left: oneOfType([number, string]),
    right: oneOfType([number, string]),
    top: oneOfType([number, string]),
    uniqueKey: string.isRequired,
    width: oneOfType([number, string]),
};

Loader.defaultProps = {
    backgroundColor: '#ECEFF5',
    backgroundOpacity: 1,
    foregroundColor: '#FFFFFF',
    foregroundOpacity: 1,
    height: 24,
    width: '100%',
};
