import React, { PureComponent } from 'react';
import { ContentLoaderBase } from 'shared';

import cx from './SkeletonLoaderBreadcrumbs.sss';

export default class SkeletonLoaderBreadcrumbs extends PureComponent {
    render() {
        return (
            <div className={cx('SkeletonLoaderBreadcrumbs')}>
                <ContentLoaderBase
                    backgroundColor="#FFFFFF"
                    className={cx('main')}
                    foregroundColor="#FFFFFF"
                    height={32}
                    speed={1.5}
                    title={null}
                    uniqueKey="SkeletonLoaderBreadcrumbs"
                    width={720}
                />
            </div>
        );
    }
}
