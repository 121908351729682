import React from 'react';

import cx from './SkeletonLoaderProfileOffers.sss';

import Loader from '../Loader';

export const SkeletonLoaderProfileOffers = () => (
    <div className={cx('root')}>
        <Loader size="large" theme="green" />
    </div>
);
