import React from 'react';
import { bool, number } from 'prop-types';

import { SkeletonLoader } from './SkeletonLoader';

const SkeletonLoaderProfilePopup = ({ size = 25, separate }) => (
    <>
        <SkeletonLoader
            desktop={{
                height: size,
                items: [
                    {
                        height: size,
                        width: 240,
                    },
                ],
            }}
            mobile={{
                height: size,
                items: [
                    {
                        height: size,
                        width: 240,
                    },
                ],
            }}
            theme="simple"
        />
        {separate && <div style={{ height: '5px' }} />}
    </>
);

SkeletonLoaderProfilePopup.propTypes = {
    separate: bool,
    size: number,
};

export { SkeletonLoaderProfilePopup };
