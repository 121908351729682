import React from 'react';
import { clone } from 'ramda';

import { SkeletonLoader } from './SkeletonLoader';

export const SkeletonLoaderProfileView = () => {
    const desktop = {
        items: [
            {
                items: [
                    {
                        items: [{ height: 24 }, { height: 24 }],
                        type: 'items',
                        width: 'calc(100% - 40px)',
                    },
                    {
                        height: 24,
                        type: 'circle',
                        width: 24,
                    },
                ],
                type: 'flex',
            },
        ],
    };

    const mobile = clone(desktop);

    mobile.items[0].items[0].items.push({ height: 24 }, { height: 24 });

    return (
        <>
            <SkeletonLoader desktop={desktop} mobile={mobile} title={200} />
            <SkeletonLoader desktop={mobile} mobile={mobile} title={200} />
            <SkeletonLoader desktop={mobile} mobile={mobile} title={200} />
        </>
    );
};
